import React, { Component, ReactNode } from 'react';
import styled, { css, cx } from 'react-emotion';
import TripLogoIcon from '../Icons/TripLogoIcon';
import { Icon } from '@traveloka/soya-components';
import { borderColor, tripLinkHover } from '@shared/styles/color';
import TripBox from '@ui-components/shared/TripBox/TripBox';
import Link from 'next/link';
import { tripPrimary } from '../../../@shared/styles/color';

// z-index 1041 because popover z-index is 1040, but cause modal to not overlay the header
const TripHeaderClass = styled(TripBox)`
  position: fixed;
  z-index: 1041;
  right: 0;
  top: 0;
  left: 0;
  height: 64px;
  background-color: white;
  border-bottom: solid 1px ${borderColor};
  align-items: center;
  padding: 0px 0px;
  a {
    color: inherit;
    text-decoration: none;
  }
  & > div {
    position: relative;
  }
  .trip--header_logo {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-content: center;
    display: inline-flex;
    border-right: 1px solid ${borderColor};
    width: 200px;
  }
  .trip--header_logo_text {
    margin-left: 16px;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 1px;
    align-items: center;
    display: inline-flex;
  }
  .trip--header_logo_img {
    align-self: center;
  }
  svg {
    max-width: 100%;
    max-height: 30px;
  }
  .trip--header_menu {
    display: inline-flex;
    justify-content: flex-end;
    flex: 1;
  }
`;

const TripHeaderItemClass = css`
  margin-left: 24px;
  position: relative;
  &:hover {
    .trip--header_item {
      color: ${tripLinkHover};
    }
  }
  .trip--header_item {
    position: relative;
    letter-spacing: 0.5px;
    font-size: 14px;
    transition: 0.3s ease;
    display: inline-flex;
    align-items: center;
    padding-right: 10px;
    & div {
      margin-bottom: 5px;
    }
    .trip--header_item_title {
      font-weight: bold;
    }
  }
  .trip--header_item_dropdown {
    right: 0;
    position: absolute;
    top: 100%;
    padding-top: 8px;
    li {
      padding: 8px 16px;
      transition: 0.3s ease;
      font-size: 14px;
      white-space: nowrap;
    }
    a {
      display: inline-flex;
      flex-grow: 0;
      line-height: 24px;
      align-items: center;
    }
    svg {
      max-width: none;
      margin-right: 8px;
      height: 16px;
    }
  }
  ul {
    li {
      cursor: pointer;
      &:hover {
        ul {
          visibility: visible;
          opacity: 1;
          transform: scale(1);
          pointer-events: auto;
        }
      }
    }
    ul {
      visibility: hidden;
      opacity: 0;
      transform: scale(0.7);
      will-change: transform;
      transform-origin: top right;
      pointer-events: none;
      transition: transform 0.15s cubic-bezier(0.4, 0, 0.2, 1) 0s,
        opacity 0.1s cubic-bezier(0.4, 0, 0.2, 1) 0s;
      li {
        &:hover {
          color: ${tripLinkHover};
        }
      }
    }
    .c-icon-chevron {
      width: 18px;
      height: 18px;
      margin-left: 4px;
      transition: 0.3s ease;
      color: ${tripPrimary};
    }
    &.trip--dropdown {
      &:hover {
        .c-icon-chevron {
          transform: rotate(-180deg);
        }
      }
    }
  }
`;

const DropDownContainer = styled(TripBox)`
  background-color: white;
  border-radius: 4px;
  overflow: hidden;
`;

export interface TopBarMenuData {
  title: string | ReactNode;
  subtitle: string;
  iconText?: ReactNode;
  links?: Object[];
  children?: ReactNode;
}

export interface TripHeaderItemProps {
  item: TopBarMenuData;
}

export interface TripHeaderProps {
  className?: string;
  poName: string;
  menuData: TopBarMenuData[];
}

// TODO(Wikan): Complete TripHeaderItem
export const TripHeaderItem = (props: TripHeaderItemProps) => {
  const { title, subtitle, links, iconText, ...rest } = props.item;
  const isDropdown = Array.isArray(links);
  const renderChild = () => {
    return (
      <ul className="trip--header_item_dropdown">
        <DropDownContainer elevation={1}>
          {links &&
            links.length > 0 &&
            links.map((item, index) => {
              return <li key={index}>{item}</li>;
            })}
        </DropDownContainer>
      </ul>
    );
  };
  return (
    <div className={TripHeaderItemClass} {...rest}>
      <ul className={isDropdown ? 'trip--dropdown' : ''}>
        <li>
          <div className="trip--header_item">
            <span>
              <div className="trip--header_item_title">{title}</div>
              <div>{subtitle}</div>
            </span>
            <div>
              {isDropdown && (
                <Icon className="c-icon-chevron" icon="chevron-down" />
              )}
            </div>
          </div>
          {isDropdown && renderChild()}
        </li>
      </ul>
    </div>
  );
};

class TripHeader extends Component<TripHeaderProps> {
  render() {
    const { className, menuData, poName, children, ...rest } = this.props;
    const classes = cx(className, {});

    return (
      <TripHeaderClass
        elevation={1}
        direction="row"
        className={classes}
        {...rest}
      >
        <div className="trip--header_logo">
          <Link href="/">
            <div
              className="trip--header_logo_img"
              style={{ cursor: 'pointer' }}
              id="trip_logo"
            >
              <TripLogoIcon />
            </div>
          </Link>
        </div>
        <div className="trip--header_logo_text">{poName}</div>
        <div className="trip--header_menu">
          {menuData.map((item, key) => {
            return <TripHeaderItem item={item} key={key} />;
          })}
        </div>
      </TripHeaderClass>
    );
  }
}

export default TripHeader;
