import {
  AUTH_READ_PROVIDER,
  AUTH_CREATE_PROVIDER,
  AUTH_READ_SKU,
  AUTH_READ_BOOKING_MANIFEST_DETAIL,
  AUTH_READ_PAX_MANIFEST,
  AUTH_SELECT_AND_SWITCH_PO,
  AUTH_READ_RESERVATION,
  AUTH_READ_ACCOUNT_MANAGEMENT,
  AUTH_READ_VEHICLE_MANAGEMENT,
  AUTH_READ_CONTROL_SETTINGS,
  AUTH_UPDATE_CONTROL_SETTINGS,
  AUTH_READ_ANALYTICS_DASHBOARD,
  AUTH_READ_POINT_CITY,
  AUTH_ADD_UPDATE_POINT_CITY,
  AUTH_READ_BANK_LIST,
  AUTH_ADD_PROVIDER_POINT_MAPPING,
  AUTH_BULK_EDIT_SKU_PROFILE,
  AUTH_BULK_UPLOAD_SKU_PHOTOS,
  AUTH_DUPLICATE_MULTI_SKU_FARES,
  AUTH_MANAGE_ROUTE_ON_AND_OFF,
  AUTH_CHECK_REMAINING_ALLOTMENT,
  AUTH_UPDATE_BULK_ALLOTMENT,
  AUTH_READ_USER_HISTORY_ALL_SKU,
  AUTH_READ_ALLOTMENT_MONITORING,
  AUTH_READ_PRICING_ENGINE,
  AUTH_READ_PRICING_SIMULATOR,
  AUTH_READ_ALLOTMENT_SEGMENT,
  AUTH_READ_RUN_API_TEST,
  AUTH_READ_ENABLEMENT_INVENTORY_API,
  AUTH_READ_CONFIG_BXT,
  AUTH_READ_PREBUY,
} from '@shared/constants/authPermission';

import { Icon } from '@traveloka/soya-components';

import IcBusDeparture from '@traveloka/icon-kit-web/react/IcBusDeparture';
import IcSystemMenuViewList from '@traveloka/icon-kit-web/react/IcSystemMenuViewList';
import IcUserBooking from '@traveloka/icon-kit-web/react/IcUserBooking';
import IcSystemToolRecentSearch from '@traveloka/icon-kit-web/react/IcSystemToolRecentSearch';
import IcFacitiesComputerStation from '@traveloka/icon-kit-web/react/IcFacilitiesComputerStation';
import IcPaymentBalanceTransfer from '@traveloka/icon-kit-web/react/IcPaymentBalanceTransfer';
import IcPaymentMoneyAmount from '@traveloka/icon-kit-web/react/IcPaymentMoneyAmount';
import IcPaymentBudgetPlanner from '@traveloka/icon-kit-web/react/IcPaymentBudgetPlanner';

import { IntegrationType } from 'trip-extranet/provider';

import { SideNavMenu } from '@ui-components/shared/TripLayout/TripSideNav';
import { tripDarkSecondary, tripUiPrimary } from '@shared/styles/color';

// NB: Need to delete after trip desktop 2.1 release is safe
// function getOldSideNavMenuData(
//   permissions: string[],
//   isInternal: boolean = false,
//   integrationType: IntegrationType = 'EXTRANET',
//   pathName: string
// ) {
//   const sideNavMenuData: SideNavMenu[] = [];

//   const mainMenu = {
//     title: '',
//     icon: (
//       <Icon
//         icon={'dashboard'}
//         color={
//           pathName === '/' || pathName.includes('/analytics/dashboard')
//             ? tripUiPrimary
//             : tripDarkSecondary
//         }
//       />
//     ),
//     isActive: pathName === '/' || pathName.includes('/analytics/dashboard'),
//     isOld: false,
//     children: [
//       {
//         title: 'Halaman Utama',
//         icon: <Icon icon={'home'} color={tripDarkSecondary} />,
//         href: '/',
//         isActive: pathName === '/',
//       },
//     ],
//   };

//   // Analytics Dashboard
//   if (permissions.includes(AUTH_READ_ANALYTICS_DASHBOARD)) {
//     mainMenu.children.push({
//       title: 'Overview',
//       icon: <Icon icon={'merge-type'} color={tripDarkSecondary} />,
//       href: '/analytics/dashboard',
//       isActive: pathName === '/analytics/dashboard',
//     });
//   }

//   sideNavMenuData.push(mainMenu);

//   // -- ALLOTMENT MONITORING
//   if (permissions.includes(AUTH_READ_ALLOTMENT_MONITORING)) {
//     sideNavMenuData.push({
//       title: 'Monitor Alokasi',
//       icon: (
//         <IcFacitiesComputerStation
//           accentColor={tripDarkSecondary}
//           color={tripDarkSecondary}
//         />
//       ),
//       isActive: pathName.includes('/allotment/monitoring'),
//       href: '/allotment/monitoring',
//     });
//   }

//   // -- PROVIDER MENU
//   if (
//     permissions.includes(AUTH_READ_PROVIDER) ||
//     permissions.includes(AUTH_SELECT_AND_SWITCH_PO)
//   ) {
//     const providerMenu = {
//       title: 'Pengaturan Data PO',
//       icon: (
//         <Icon
//           icon={'domain'}
//           color={
//             pathName === '/po' || pathName.includes('/po/')
//               ? tripUiPrimary
//               : tripDarkSecondary
//           }
//         />
//       ),
//       isOld: true,
//       isActive: pathName === '/po' || pathName.includes('/po/'),
//       children: [
//         {
//           title: 'Lihat Data PO',
//           href: '/po',
//           isActive: pathName === '/po',
//         },
//       ],
//     };

//     if (permissions.includes(AUTH_CREATE_PROVIDER)) {
//       providerMenu.children.push({
//         title: 'Tambah PO',
//         href: '/po/new',
//         isActive: pathName === '/po/new',
//       });
//     }

//     sideNavMenuData.push(providerMenu);
//   }

//   // -- SKU / INVENTORY MENU
//   if (permissions.includes(AUTH_READ_SKU)) {
//     const skuMenu = {
//       title: 'Pengaturan Inventori',
//       icon: (
//         <Icon
//           icon={'business-center'}
//           color={
//             pathName === '/sku' || pathName.includes('/sku/')
//               ? tripUiPrimary
//               : tripDarkSecondary
//           }
//         />
//       ),
//       isOld: true,
//       isActive: pathName === '/sku' || pathName.includes('/sku/'),
//       children: [
//         {
//           title: 'Daftar Trayek',
//           href: '/sku/view',
//           isActive: pathName === '/sku/view',
//         },
//         {
//           title: 'Draf Trayek',
//           href: '/sku/view/draft',
//           isActive: pathName === '/sku/view/draft',
//         },
//         {
//           title: 'Arsip Trayek',
//           href: '/sku/view/archived',
//           isActive: pathName === '/sku/view/archived',
//         },
//         {
//           title: 'Butuh Moderasi',
//           href: '/sku/view/moderation',
//           isActive: pathName === '/sku/view/moderation',
//         },
//       ],
//     };

//     if (isInternal && integrationType === 'API') {
//       skuMenu.children.push({
//         title: 'Daftar Trayek Dasar',
//         href: '/base-sku/list',
//         isActive: pathName === '/base-sku/list',
//       });
//     }

//     sideNavMenuData.push(skuMenu);
//   }

//   // -- BOOKING MANIFEST MENU
//   if (permissions.includes(AUTH_READ_BOOKING_MANIFEST_DETAIL)) {
//     const bookingManifestMenu = {
//       title: 'Pesanan / Transaksi',
//       icon: (
//         <Icon
//           icon={'list'}
//           color={
//             pathName.includes('/manifest/booking-old')
//               ? tripUiPrimary
//               : tripDarkSecondary
//           }
//         />
//       ),
//       isOld: true,
//       isActive: pathName.includes('/manifest/booking-old'),
//       children: [
//         {
//           title: 'Daftar Pesanan',
//           href: '/manifest/booking-old',
//           isActive: pathName.includes('/manifest/booking-old'),
//         },
//       ],
//     };

//     sideNavMenuData.push(bookingManifestMenu);
//   }

//   // -- PAX MANIFEST MENU
//   if (permissions.includes(AUTH_READ_PAX_MANIFEST)) {
//     const paxManifestMenu = {
//       title: 'Perjalanan',
//       icon: (
//         <Icon
//           icon={'near-me'}
//           color={
//             pathName.includes('/manifest/passenger-old')
//               ? tripUiPrimary
//               : tripDarkSecondary
//           }
//         />
//       ),
//       isOld: true,
//       isActive: pathName.includes('/manifest/passenger-old'),
//       children: [
//         {
//           title: 'Daftar Penumpang',
//           href: '/manifest/passenger-old',
//           isActive: pathName.includes('/manifest/passenger-old'),
//         },
//       ],
//     };

//     sideNavMenuData.push(paxManifestMenu);
//   }

//   // -- RESERVATION MENU
//   if (permissions.includes(AUTH_READ_RESERVATION)) {
//     const reservationMenu = {
//       title: 'Pemesanan',
//       icon: (
//         <Icon
//           icon={'today'}
//           color={
//             pathName.includes('/reservation')
//               ? tripUiPrimary
//               : tripDarkSecondary
//           }
//         />
//       ),
//       isOld: true,
//       isActive: pathName.includes('/reservation'),
//       children: [
//         {
//           title: 'Daftar Pemesanan',
//           href: '/reservation',
//           isActive: pathName.includes('/reservation'),
//         },
//       ],
//     };

//     sideNavMenuData.push(reservationMenu);
//   }

//   // VEHICLE MANAGEMENT MENU
//   if (permissions.includes(AUTH_READ_VEHICLE_MANAGEMENT)) {
//     const vehicleManagementMenu = {
//       title: 'Kendaraan',
//       icon: (
//         <Icon
//           icon={'directions-bus'}
//           color={
//             pathName.includes('/manage/vehicle')
//               ? tripUiPrimary
//               : tripDarkSecondary
//           }
//         />
//       ),
//       isOld: true,
//       isActive: pathName.includes('/manage/vehicle'),
//       children: [
//         {
//           title: 'Daftar Kendaraan',
//           href: '/manage/vehicle',
//           isActive: pathName.includes('/manage/vehicle'),
//         },
//       ],
//     };

//     sideNavMenuData.push(vehicleManagementMenu);
//   }

//   // -- ACCOUNT MANAGEMENT MENU
//   if (permissions.includes(AUTH_READ_ACCOUNT_MANAGEMENT)) {
//     const accountManagementMenu = {
//       title: 'Pengaturan Akun',
//       icon: (
//         <Icon
//           icon={'person'}
//           color={
//             pathName === '/manage/user-account'
//               ? tripUiPrimary
//               : tripDarkSecondary
//           }
//         />
//       ),
//       isOld: true,
//       isActive: pathName === '/manage/user-account',
//       children: [
//         {
//           title: 'Atur Akun',
//           href: '/manage/user-account',
//           isActive: pathName === '/manage/user-account',
//         },
//       ],
//     };

//     sideNavMenuData.push(accountManagementMenu);
//   }

//   // -- CONTROL SETTINGS MENU
//   if (
//     permissions.includes(AUTH_READ_CONTROL_SETTINGS) &&
//     permissions.includes(AUTH_UPDATE_CONTROL_SETTINGS)
//   ) {
//     const controlSettingsMenu = {
//       title: 'Control Settings',
//       icon: (
//         <Icon
//           icon={'settings'}
//           color={
//             pathName.includes('/control-settings/booking') ||
//             pathName.includes('/control-settings/pax-manifest')
//               ? tripUiPrimary
//               : tripDarkSecondary
//           }
//         />
//       ),
//       isOld: true,
//       isActive: pathName === '/control-settings/pax-manifest',
//       children: [
//         {
//           title: 'Booking',
//           href: '/control-settings/booking',
//           isActive: pathName === '/control-settings/booking',
//         },
//         {
//           title: 'Pax Manifest',
//           href: '/control-settings/pax-manifest',
//           isActive: pathName === '/control-settings/pax-manifest',
//         },
//       ],
//     };

//     sideNavMenuData.push(controlSettingsMenu);
//   }

//   // POSTMAN UTILS MENU
//   const postmanLinks = [];

//   if (permissions.includes(AUTH_DUPLICATE_MULTI_SKU_FARES)) {
//     postmanLinks.push({
//       title: 'Duplicate Multi SKU Fares',
//       href: '/utils/sku/duplicate-fares',
//       isActive: pathName === '/utils/sku/duplicate-fares',
//     });
//   }

//   if (permissions.includes(AUTH_UPDATE_BULK_ALLOTMENT)) {
//     postmanLinks.push({
//       title: 'Bulk Duplicate Allotment',
//       href: '/utils/sku/duplicate-allotment',
//       isActive: pathName === '/utils/sku/duplicate-allotment',
//     });
//   }

//   if (permissions.includes(AUTH_BULK_EDIT_SKU_PROFILE)) {
//     postmanLinks.push({
//       title: 'Bulk Edit R&R',
//       href: '/utils/sku/edit-profile',
//       isActive: pathName === '/utils/sku/edit-profile',
//     });
//   }

//   if (permissions.includes(AUTH_BULK_UPLOAD_SKU_PHOTOS)) {
//     postmanLinks.push({
//       title: 'Upload SKU Photos',
//       href: '/utils/sku/upload-photo',
//       isActive: pathName === '/utils/sku/upload-photo',
//     });
//   }

//   if (permissions.includes(AUTH_READ_POINT_CITY)) {
//     postmanLinks.push(
//       {
//         title: 'View City List',
//         href: '/utils/city/view',
//         isActive: pathName === '/utils/city/view',
//       },
//       {
//         title: 'View Point List',
//         href: '/utils/point/view',
//         isActive: pathName === '/utils/point/view',
//       }
//     );
//   }

//   if (permissions.includes(AUTH_ADD_UPDATE_POINT_CITY)) {
//     postmanLinks.push(
//       {
//         title: 'Add City',
//         href: '/utils/city/add',
//         isActive: pathName === '/utils/city/add',
//       },
//       {
//         title: 'Edit City',
//         href: '/utils/city/edit',
//         isActive: pathName === '/utils/city/edit',
//       },
//       {
//         title: 'Add Point',
//         href: '/utils/point/add',
//         isActive: pathName === '/utils/point/add',
//       },
//       {
//         title: 'Edit Point',
//         href: '/utils/point/edit',
//         isActive: pathName === '/utils/point/edit',
//       }
//     );
//   }

//   if (permissions.includes(AUTH_ADD_PROVIDER_POINT_MAPPING)) {
//     postmanLinks.push({
//       title: 'PO Point Mapping',
//       href: '/utils/point/provider-mapping',
//       isActive: pathName === '/utils/point/provider-mapping',
//     });
//   }

//   if (permissions.includes(AUTH_READ_BANK_LIST)) {
//     postmanLinks.push({
//       title: 'Bank List',
//       href: '/utils/bank/list',
//       isActive: pathName === '/utils/bank/list',
//     });
//   }

//   if (permissions.includes(AUTH_MANAGE_ROUTE_ON_AND_OFF)) {
//     postmanLinks.push({
//       title: 'Buka/Tutup Rute Area',
//       href: '/utils/route/manage',
//       isActive: pathName === '/utils/route/manage',
//     });
//   }

//   if (permissions.includes(AUTH_CHECK_REMAINING_ALLOTMENT)) {
//     postmanLinks.push({
//       title: 'Check Remaining Allotment',
//       href: '/utils/allotment/check',
//       isActive: pathName === '/utils/allotment/check',
//     });
//   }

//   if (permissions.includes(AUTH_ADD_PROVIDER_POINT_MAPPING)) {
//     postmanLinks.push({
//       title: 'Missing Points',
//       href: '/utils/point/unmapped-points',
//       isActive: pathName === '/utils/point/unmapped-points',
//     });
//   }

//   if (permissions.includes(AUTH_UPDATE_BULK_ALLOTMENT)) {
//     postmanLinks.push({
//       title: 'Bulk Update Allotment Status',
//       href: '/utils/allotment/update-status',
//       isActive: pathName === '/utils/allotment/update-status',
//     });
//     postmanLinks.push({
//       title: 'Bulk Update Allotment [NSS]',
//       href: '/utils/allotment/update-multiple-sku',
//       isActive: pathName === '/utils/allotment/update-multiple-sku',
//     });
//   }

//   if (postmanLinks.length > 0) {
//     const isPostmanLinkActive = postmanLinks.find((link) => link.isActive);

//     sideNavMenuData.push({
//       title: 'Postman Utils',
//       icon: (
//         <Icon
//           icon={'extension'}
//           color={
//             isPostmanLinkActive && isPostmanLinkActive.isActive
//               ? tripUiPrimary
//               : tripDarkSecondary
//           }
//         />
//       ),
//       isOld: true,
//       isActive: Boolean(isPostmanLinkActive && isPostmanLinkActive.isActive),
//       children: postmanLinks,
//     });
//   }

//   return sideNavMenuData;
// }

function getNewSideNavMenuData(
  permissions: string[],
  isInternal: boolean = false,
  integrationType: IntegrationType = 'EXTRANET',
  pathName: string
) {
  const sideNavMenuData: SideNavMenu[] = [];

  const mainMenu = {
    title: '',
    icon: (
      <Icon
        icon={'dashboard'}
        color={
          pathName === '/' || pathName.includes('/analytics/dashboard')
            ? tripUiPrimary
            : tripDarkSecondary
        }
      />
    ),
    isActive: pathName === '/' || pathName.includes('/analytics/dashboard'),
    isOld: false,
    children: [
      {
        title: 'Halaman Utama',
        icon: <Icon icon={'home'} color={tripDarkSecondary} />,
        href: '/',
        isActive: pathName === '/',
      },
    ],
  };

  // Analytics Dashboard
  if (permissions.includes(AUTH_READ_ANALYTICS_DASHBOARD)) {
    mainMenu.children.push({
      title: 'Overview',
      icon: <Icon icon={'merge-type'} color={tripDarkSecondary} />,
      href: '/analytics/dashboard',
      isActive: pathName === '/analytics/dashboard',
    });
  }

  sideNavMenuData.push(mainMenu);

  // -- PAX MANIFEST MENU
  if (permissions.includes(AUTH_READ_PAX_MANIFEST)) {
    sideNavMenuData.push({
      title: 'Jadwal Keberangkatan',
      icon: (
        <IcBusDeparture
          accentColor={
            pathName.includes('/manifest/passenger')
              ? tripUiPrimary
              : tripDarkSecondary
          }
          color={
            pathName.includes('/manifest/passenger')
              ? tripUiPrimary
              : tripDarkSecondary
          }
        />
      ),
      isActive: pathName.includes('/manifest/passenger'),
      href: '/manifest/passenger',
    });
  }

  // -- SKU / INVENTORY MENU
  if (permissions.includes(AUTH_READ_SKU)) {
    const skuMenu = {
      title: 'Daftar Inventori',
      icon: (
        <IcSystemMenuViewList
          accentColor={
            (pathName.includes('/sku') && !pathName.includes('utils')) ||
            pathName.includes('/base-sku/list')
              ? tripUiPrimary
              : tripDarkSecondary
          }
          color={
            (pathName.includes('/sku') && !pathName.includes('utils')) ||
            pathName.includes('/base-sku/list')
              ? tripUiPrimary
              : tripDarkSecondary
          }
        />
      ),

      isActive:
        (pathName.includes('/sku') && !pathName.includes('utils')) ||
        pathName.includes('/base-sku/list'),
      children: [
        {
          title: 'Daftar Inventori',
          href: '/sku',

          isActive: pathName === '/sku',
          icon: (
            <IcSystemMenuViewList
              accentColor={tripDarkSecondary}
              color={tripDarkSecondary}
            />
          ),
        },
      ],
    };

    if (isInternal && integrationType === 'API') {
      skuMenu.children.push({
        title: 'Daftar Trayek Dasar',
        href: '/base-sku/list',
        isActive: pathName === '/base-sku/list',
        icon: (
          <IcSystemMenuViewList
            accentColor={tripDarkSecondary}
            color={tripDarkSecondary}
          />
        ),
      });
    }

    sideNavMenuData.push(skuMenu);
  }

  if (permissions.includes(AUTH_READ_BOOKING_MANIFEST_DETAIL)) {
    sideNavMenuData.push({
      title: 'Laporan Penjualan',
      icon: (
        <IcUserBooking
          accentColor={
            pathName.includes('/manifest/booking')
              ? tripUiPrimary
              : tripDarkSecondary
          }
          color={
            pathName.includes('/manifest/booking')
              ? tripUiPrimary
              : tripDarkSecondary
          }
        />
      ),
      isActive: pathName.includes('/manifest/booking'),
      href: '/manifest/booking',
    });
  }

  if (permissions.includes(AUTH_READ_USER_HISTORY_ALL_SKU)) {
    sideNavMenuData.push({
      title: 'Riwayat Perubahan',
      icon: (
        <IcSystemToolRecentSearch
          accentColor={
            pathName.includes('/history') ? tripUiPrimary : tripDarkSecondary
          }
          color={
            pathName.includes('/history') ? tripUiPrimary : tripDarkSecondary
          }
        />
      ),
      isActive: pathName.includes('/history'),
      href: '/history',
    });
  }

  // -- ALLOTMENT MONITORING
  if (permissions.includes(AUTH_READ_ALLOTMENT_MONITORING)) {
    sideNavMenuData.push({
      title: 'Monitor Alokasi',
      icon: (
        <IcFacitiesComputerStation
          accentColor={tripDarkSecondary}
          color={tripDarkSecondary}
        />
      ),
      isActive: pathName.includes('/allotment/monitoring'),
      href: '/allotment/monitoring',
    });
  }

  // -- PRICING ENGINE
  // TODO: Change icon if available.
  if (permissions.includes(AUTH_READ_PRICING_ENGINE)) {
    sideNavMenuData.push({
      title: 'Paket Harga',
      icon: (
        <IcPaymentBalanceTransfer
          accentColor={
            pathName.includes('/pricing-engine')
              ? tripUiPrimary
              : tripDarkSecondary
          }
          color={
            pathName.includes('/pricing-engine')
              ? tripUiPrimary
              : tripDarkSecondary
          }
        />
      ),
      isActive: pathName.includes('/pricing-engine'),
      href: '/pricing-engine',
    });
  }

  if (permissions.includes(AUTH_READ_PRICING_SIMULATOR)) {
    sideNavMenuData.push({
      title: 'Simulasi Harga',
      icon: (
        <IcPaymentMoneyAmount
          accentColor={
            pathName.includes('/pricing-simulator')
              ? tripUiPrimary
              : tripDarkSecondary
          }
          color={
            pathName.includes('/pricing-simulator')
              ? tripUiPrimary
              : tripDarkSecondary
          }
        />
      ),
      isActive: pathName.includes('/pricing-simulator'),
      href: '/pricing-simulator',
    });
  }

  if (permissions.includes(AUTH_READ_PREBUY)) {
    const url = '/prebuy-inventory/purchase';
    const isActive = pathName.includes(url);
    sideNavMenuData.push({
      title: 'Prebuy Inventory',
      icon: (
        <IcPaymentBudgetPlanner
          accentColor={isActive ? tripUiPrimary : tripDarkSecondary}
          color={isActive ? tripUiPrimary : tripDarkSecondary}
        />
      ),
      isActive,
      href: url,
    });
  }

  // -- PROVIDER MENU
  if (
    permissions.includes(AUTH_READ_PROVIDER) ||
    permissions.includes(AUTH_SELECT_AND_SWITCH_PO)
  ) {
    const providerMenu = {
      title: 'Pengaturan Data PO',
      icon: (
        <Icon
          icon={'domain'}
          color={
            pathName === '/po' || pathName.includes('/po/')
              ? tripUiPrimary
              : tripDarkSecondary
          }
        />
      ),
      isActive: pathName === '/po' || pathName.includes('/po/'),
      isOld: true,
      children: [
        {
          title: 'Lihat Data PO',
          href: '/po',
          isActive: pathName === '/po',
        },
      ],
    };

    if (permissions.includes(AUTH_CREATE_PROVIDER)) {
      providerMenu.children.push({
        title: 'Tambah PO',
        href: '/po/new',
        isActive: pathName === '/po/new',
      });
    }

    sideNavMenuData.push(providerMenu);
  }

  // -- RESERVATION MENU
  if (permissions.includes(AUTH_READ_RESERVATION)) {
    const reservationMenu = {
      title: 'Pemesanan',
      icon: (
        <Icon
          icon={'today'}
          color={
            pathName.includes('/reservation')
              ? tripUiPrimary
              : tripDarkSecondary
          }
        />
      ),
      isActive: pathName.includes('/reservation'),
      isOld: true,
      children: [
        {
          title: 'Daftar Pemesanan',
          href: '/reservation',
          isActive: pathName === '/reservation',
        },
      ],
    };

    sideNavMenuData.push(reservationMenu);
  }

  // VEHICLE MANAGEMENT MENU
  if (permissions.includes(AUTH_READ_VEHICLE_MANAGEMENT)) {
    const vehicleManagementMenu = {
      title: 'Kendaraan',
      icon: (
        <Icon
          icon={'directions-bus'}
          color={
            pathName.includes('/manage/vehicle')
              ? tripUiPrimary
              : tripDarkSecondary
          }
        />
      ),
      isActive: pathName.includes('/manage/vehicle'),
      isOld: true,
      children: [
        {
          title: 'Daftar Kendaraan',
          href: '/manage/vehicle',
          isActive: pathName === '/manage/vehicle',
        },
      ],
    };
    sideNavMenuData.push(vehicleManagementMenu);
  }

  // -- ACCOUNT MANAGEMENT MENU
  if (permissions.includes(AUTH_READ_ACCOUNT_MANAGEMENT)) {
    const accountManagementMenu = {
      title: 'Pengaturan Akun',
      icon: (
        <Icon
          icon={'person'}
          color={
            pathName.includes('/manage/user-account')
              ? tripUiPrimary
              : tripDarkSecondary
          }
        />
      ),
      isActive: pathName.includes('/manage/user-account'),
      isOld: true,
      children: [
        {
          title: 'Atur Akun',
          href: '/manage/user-account',
          isActive: pathName === '/manage/user-account',
        },
      ],
    };

    sideNavMenuData.push(accountManagementMenu);
  }

  // -- CONTROL SETTINGS MENU
  if (
    permissions.includes(AUTH_READ_CONTROL_SETTINGS) &&
    permissions.includes(AUTH_UPDATE_CONTROL_SETTINGS)
  ) {
    const pathNameGroups = [
      '/control-settings/booking',
      '/control-settings/pax-manifest',
      '/control-settings/allotment-monitoring',
    ];
    const controlSettingsMenu = {
      title: 'Control Settings',
      icon: (
        <Icon
          icon={'settings'}
          color={
            pathNameGroups.includes(pathName)
              ? tripUiPrimary
              : tripDarkSecondary
          }
        />
      ),
      isActive: pathNameGroups.includes(pathName),
      isOld: true,
      children: [
        {
          title: 'Booking',
          href: '/control-settings/booking',
          isActive: pathName === '/control-settings/booking',
        },
        {
          title: 'Pax Manifest',
          href: '/control-settings/pax-manifest',
          isActive: pathName === '/control-settings/pax-manifest',
        },
        {
          title: 'Monitor Alokasi',
          href: '/control-settings/allotment-monitoring',
          isActive: pathName === '/control-settings/allotment-monitoring',
        },
      ],
    };

    if (
      permissions.includes(AUTH_READ_ENABLEMENT_INVENTORY_API) &&
      integrationType === 'API'
    ) {
      controlSettingsMenu.children.push({
        title: 'Enable / Disable Inventory API',
        href: '/control-settings/enablement-inventory-api',
        isActive: pathName === '/control-settings/enablement-inventory-api',
      });
    }

    sideNavMenuData.push(controlSettingsMenu);
  }

  // POSTMAN UTILS MENU
  const postmanLinks = [];

  if (permissions.includes(AUTH_DUPLICATE_MULTI_SKU_FARES)) {
    postmanLinks.push({
      title: 'Duplicate Multi SKU Fares',
      href: '/utils/sku/duplicate-fares',
      isActive: pathName === '/utils/sku/duplicate-fares',
    });
  }

  if (permissions.includes(AUTH_UPDATE_BULK_ALLOTMENT)) {
    postmanLinks.push({
      title: 'Bulk Duplicate Allotment',
      href: '/utils/sku/duplicate-allotment',
      isActive: pathName === '/utils/sku/duplicate-allotment',
    });
  }

  if (permissions.includes(AUTH_BULK_EDIT_SKU_PROFILE)) {
    postmanLinks.push({
      title: 'Bulk Edit R&R',
      href: '/utils/sku/edit-profile',
      isActive: pathName === '/utils/sku/edit-profile',
    });
  }

  if (permissions.includes(AUTH_BULK_EDIT_SKU_PROFILE)) {
    postmanLinks.push({
      title: 'Special R&R List',
      href: '/utils/sku/special-refund-reschedule',
      isActive: pathName === '/utils/sku/special-refund-reschedule',
    });
  }

  if (permissions.includes(AUTH_BULK_UPLOAD_SKU_PHOTOS)) {
    postmanLinks.push({
      title: 'Upload SKU Photos',
      href: '/utils/sku/upload-photo',
      isActive: pathName === '/utils/sku/upload-photo',
    });
  }

  if (permissions.includes(AUTH_READ_POINT_CITY)) {
    postmanLinks.push(
      {
        title: 'View City List',
        href: '/utils/city/view',
        isActive: pathName === '/utils/city/view',
      },
      {
        title: 'View Point List',
        href: '/utils/point/view',
        isActive: pathName === '/utils/point/view',
      }
    );
  }

  if (permissions.includes(AUTH_ADD_UPDATE_POINT_CITY)) {
    postmanLinks.push(
      {
        title: 'Add City',
        href: '/utils/city/add',
        isActive: pathName === '/utils/city/add',
      },
      {
        title: 'Edit City',
        href: '/utils/city/edit',
        isActive: pathName === '/utils/city/edit',
      },
      {
        title: 'Add Point',
        href: '/utils/point/add',
        isActive: pathName === '/utils/point/add',
      },
      {
        title: 'Edit Point',
        href: '/utils/point/edit',
        isActive: pathName === '/utils/point/edit',
      }
    );
  }

  if (permissions.includes(AUTH_ADD_PROVIDER_POINT_MAPPING)) {
    postmanLinks.push({
      title: 'PO Point Mapping',
      href: '/utils/point/provider-mapping',
      isActive: pathName === '/utils/point/provider-mapping',
    });
  }

  if (permissions.includes(AUTH_READ_BANK_LIST)) {
    postmanLinks.push({
      title: 'Bank List',
      href: '/utils/bank/list',
      isActive: pathName === '/utils/bank/list',
    });
  }

  if (permissions.includes(AUTH_MANAGE_ROUTE_ON_AND_OFF)) {
    postmanLinks.push({
      title: 'Buka/Tutup Rute Area',
      href: '/utils/route/manage',
      isActive: pathName === '/utils/route/manage',
    });
  }

  if (permissions.includes(AUTH_CHECK_REMAINING_ALLOTMENT)) {
    postmanLinks.push({
      title: 'Check Remaining Allotment',
      href: '/utils/allotment/check',
      isActive: pathName === '/utils/allotment/check',
    });
  }

  if (permissions.includes(AUTH_ADD_PROVIDER_POINT_MAPPING)) {
    postmanLinks.push({
      title: 'Missing Points',
      href: '/utils/point/unmapped-points',
      isActive: pathName === '/utils/point/unmapped-points',
    });
  }

  if (permissions.includes(AUTH_READ_PROVIDER)) {
    postmanLinks.push({
      title: 'Missing Vessels',
      href: '/utils/provider/unmapped-vessels',
      isActive: pathName === '/utils/provider/unmapped-vessels',
    });
  }

  if (permissions.includes(AUTH_UPDATE_BULK_ALLOTMENT)) {
    postmanLinks.push({
      title: 'Bulk Update Allotment Status',
      href: '/utils/allotment/update-status',
      isActive: pathName === '/utils/allotment/update-status',
    });
    postmanLinks.push({
      title: 'Bulk Update Allotment [NSS]',
      href: '/utils/allotment/update-multiple-sku',
      isActive: pathName === '/utils/allotment/update-multiple-sku',
    });
  }

  if (permissions.includes(AUTH_READ_ALLOTMENT_SEGMENT)) {
    postmanLinks.push({
      title: 'Partial Route Monitor',
      href: '/utils/route/partial/monitor',
      isActive: pathName === '/utils/route/partial/monitor',
    });
  }

  if (permissions.includes(AUTH_READ_RUN_API_TEST)) {
    postmanLinks.push({
      title: 'Assessment Enablement API',
      href: '/utils/assessment-enablement-api',
      isActive: pathName === '/utils/assessment-enablement-api',
    });
  }

  if (postmanLinks.length > 0) {
    const isPostmanLinkActive = postmanLinks.find((link) => link.isActive);

    sideNavMenuData.push({
      title: 'Postman Utils',
      icon: (
        <Icon
          icon={'extension'}
          color={
            isPostmanLinkActive && isPostmanLinkActive.isActive
              ? tripUiPrimary
              : tripDarkSecondary
          }
        />
      ),
      isOld: true,
      isActive: Boolean(isPostmanLinkActive && isPostmanLinkActive.isActive),
      children: postmanLinks,
    });
  }

  if (permissions.includes(AUTH_READ_CONFIG_BXT)) {
    postmanLinks.push({
      title: 'BXT Config',
      href: '/utils/bxt-config',
      isActive: pathName === '/bxt-config',
    });
  }

  return sideNavMenuData;
}

export default function getSideNavMenuData(
  permissions: string[],
  isInternal: boolean = false,
  integrationType: IntegrationType = 'EXTRANET',
  pathName: string
) {
  // const sideNavMenuData = isInternal
  //   ? getNewSideNavMenuData(permissions, isInternal, integrationType, pathName)
  //   : getOldSideNavMenuData(permissions, isInternal, integrationType, pathName);

  return getNewSideNavMenuData(
    permissions,
    isInternal,
    integrationType,
    pathName
  );
}
