import { FlexBox, FlexBoxProps } from '@traveloka/soya-components';
import { PureComponent, ReactNode } from 'react';
import { borderColor } from '@shared/styles/color';
import { css, cx } from 'react-emotion';

export const elevationProvider = {
  0: 'none',
  1: '0 1px 3px 0 rgba(27,27,27,0.10)',
  2: '0 2px 4px 0 rgba(27,27,27,0.20)',
  3: '0 4px 8px 0 rgba(27,27,27,0.30), 0 1px 3px 0 rgba(27,27,27,0.20)',
} as { [key: number]: string };

export interface TripBoxProps extends FlexBoxProps {
  elevation?: number;
  className?: string;
  children?: ReactNode | string;
}

const TripBoxStyle = css`
  border: 1px solid ${borderColor};
  background-color: white;
`;

class TripBox extends PureComponent<TripBoxProps> {
  static displayName = 'TripBox';

  static defaultProps = {
    elevation: 0,
    direction: 'column',
  } as TripBoxProps;

  render() {
    const {
      elevation,
      children,
      className,
      onClick = () => {},
      ...rest
    } = this.props;
    const boxShadowStyle = elevation ? elevationProvider[elevation] : 'none';
    const boxShadowClass = css`
      box-shadow: ${boxShadowStyle};
    `;
    const classes = cx(boxShadowClass, TripBoxStyle, className);
    return (
      <FlexBox {...rest} onClick={onClick} className={classes}>
        {children}
      </FlexBox>
    );
  }
}

export default TripBox;
