import createFetchReducer, {
  FetchState,
} from '@shared/utils/states/createThunkFetchReducer';
import { UserConfig } from 'trip-extranet/user';
import { ProviderProfile } from 'trip-extranet/provider';

import {
  startLoadingUserConfigAction,
  finishLoadingUserConfigAction,
} from './fetchUserSettingActions';

import {
  startLoadingProviderProfile,
  finishLoadingProviderProfile,
} from './fetchProviderProfileActions';

export type UserConfigState = FetchState<UserConfig>;
export type ProviderProfileState = FetchState<ProviderProfile>;

const initialStateUserConfig: UserConfigState = {
  initiated: false,
  loading: true,
  successFetch: false,
};

const initialStateProviderProfile: ProviderProfileState = {
  initiated: false,
  loading: true,
  successFetch: false,
};

const userConfigReducer = createFetchReducer({
  startAction: startLoadingUserConfigAction,
  finishedAction: finishLoadingUserConfigAction,
  initialState: initialStateUserConfig,
});

const providerProfileReducer = createFetchReducer({
  initialState: initialStateProviderProfile,
  startAction: startLoadingProviderProfile,
  finishedAction: finishLoadingProviderProfile,
});

export interface UserConfigReduxState {
  userConfig: UserConfigState;
  providerProfile: ProviderProfileState;
}

export default {
  userConfig: userConfigReducer,
  providerProfile: providerProfileReducer,
};
