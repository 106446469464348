import { createAction } from 'redux-actions';
import { UserConfig } from 'trip-extranet/user';
import thunkFetch from '@shared/utils/states/thunkFetch';
import fetchUserConfig, {
  FetchUserConfigSpec,
} from 'account-management/services/fetchUserConfig';

export const startLoadingUserConfigAction = createAction(
  'USER/START_LOAD_USER_CONFIG'
);
export const finishLoadingUserConfigAction = createAction<UserConfig>(
  'USER/FINISHED_LOAD_USER_CONFIG'
);

export const fetchUserConfigAction = (payload: FetchUserConfigSpec) =>
  thunkFetch({
    startAction: startLoadingUserConfigAction,
    finishedAction: finishLoadingUserConfigAction,
    method: () => fetchUserConfig(payload),
    extractData: (response) => ({
      providerId: response.providerId,
      providerCommercialName: response.providerCommercialName,
      marketManagerName: response.marketManagerName,
      userEmail: response.userEmail,
      isInternal: response.isInternal,
    }),
    checkStatus: false,
  });
