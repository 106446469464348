import { UserConfigReduxState } from 'account-management/states/UserConfigReducer';
import { getLocalStorageValue } from '@shared/utils/storage/LocalStorage';

export default function providerIdSelector(state: UserConfigReduxState) {
  const storedProviderId = getLocalStorageValue('selected_provider_id');

  return (
    storedProviderId ||
    (state.userConfig &&
      state.userConfig.data &&
      state.userConfig.data.providerId)
  );
}
