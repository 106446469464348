import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { Loader } from '@traveloka/soya-components';
import Layout, { LayoutProps } from '@shared/components/Layout/Layout';
import { fetchUserConfigAction } from 'account-management/states/fetchUserSettingActions';
import { applyReducers } from 'soya-next/redux';
import UserConfigReducer, {
  UserConfigReduxState,
} from 'account-management/states/UserConfigReducer';
import { PageContextConsumer } from '@shared/contexts/PageContext';
import { AnyThunkDispatch } from '@traveloka/commons';
import providerIdSelector from 'account-management/selectors/providerIdSelector';
import Router from 'next/router';
import pageHistoryUrlReducer from '@shared/states/pageHistoryUrlReducer';
import { setPrevUrlAction } from '@shared/states/pageHistoryUrlActions';
import { FetchUserConfigSpec } from 'account-management/services/fetchUserConfig';
import {
  AUTH_ACCESS_TRIP,
  AUTH_READ_PROVIDER,
} from '@shared/constants/authPermission';
import { removeLocalStorageValue } from '@shared/utils/storage/LocalStorage';
import getRouterQuerystring from '@shared/utils/next-router/getRouterQuerystring';
import { getProviderOperationalDataAction } from 'provider/provider-operational-data/states/getProviderOperationalDataActions';
import providerOperationalDataReducer, {
  ProviderOperationalDataReduxState,
} from 'provider/provider-operational-data/states/providerOperationalDataReducer';
import { UrlLike } from 'trip-extranet/commons';
import { getPermissions } from '@shared/utils/permissions';

type State = UserConfigReduxState & {
  oidc: any;
} & ProviderOperationalDataReduxState;

const mapStateToProps = (state: State) => ({
  providerId: providerIdSelector(state),
  providerCommercialName:
    state.userConfig.data && state.userConfig.data.providerCommercialName,
  marketManagerName:
    state.userConfig.data && state.userConfig.data.marketManagerName,
  userEmail: state.userConfig.data && state.userConfig.data.userEmail,
  permissions: getPermissions(state) || [],
  permissionsLoaded: state.oidc && state.oidc.token && state.oidc.token.loaded,
  integrationType:
    state.providerOperationalData.data &&
    state.providerOperationalData.data.integrationType,
  isInternal: state.userConfig.data && state.userConfig.data.isInternal,
});

const mapDispatchToProps = (dispatch: AnyThunkDispatch) => ({
  getUserConfig: (payload: FetchUserConfigSpec) =>
    dispatch(fetchUserConfigAction(payload)),
  setPrevUrl: (url: UrlLike) => dispatch(setPrevUrlAction(url)),
  getProviderOperationalData: (providerId: string) =>
    dispatch(getProviderOperationalDataAction(providerId)),
});

interface LayoutContainerProps extends LayoutProps {
  providerId?: string;
  getUserConfig: (payload: FetchUserConfigSpec) => any;
  getProviderProfile: (providerId: string) => any;
  getProviderOperationalData: (providerId: string) => any;
  setPrevUrl: (url: UrlLike) => any;
}

interface LayoutContainerState {
  hasUserConfig: boolean;
}

class LayoutContainer extends React.Component<
  LayoutContainerProps,
  LayoutContainerState
> {
  constructor(props: LayoutContainerProps) {
    super(props);

    this.state = {
      hasUserConfig: false,
    };
  }

  componentDidMount() {
    this.getDatas();

    Router.events.on('routeChangeStart', () => {
      this.props.setPrevUrl({
        pathname: Router.pathname,
        query: getRouterQuerystring(),
      });
    });

    if (
      this.props.permissionsLoaded &&
      !this.props.permissions.includes(AUTH_ACCESS_TRIP)
    ) {
      removeLocalStorageValue('access_token');
      removeLocalStorageValue('id_token');
      removeLocalStorageValue('expires_at');
      removeLocalStorageValue('expires_in');
      removeLocalStorageValue('selected_provider_id');

      location.href = '/';
    }

    const { isInternal, integrationType } = this.props;

    if (
      Router.pathname.includes('base-sku') &&
      (!isInternal || integrationType !== 'API')
    ) {
      Router.replace('/');
    }
  }

  componentDidUpdate(prevProps: LayoutContainerProps) {
    if (
      !prevProps.permissionsLoaded &&
      this.props.permissionsLoaded &&
      !this.props.permissions.includes(AUTH_ACCESS_TRIP)
    ) {
      removeLocalStorageValue('access_token');
      removeLocalStorageValue('id_token');
      removeLocalStorageValue('expires_at');
      removeLocalStorageValue('expires_in');
      removeLocalStorageValue('selected_provider_id');

      location.href = '/';
    }

    if (prevProps.providerId !== this.props.providerId) this.getDatas();
  }

  async getDatas() {
    const { providerId } = this.props;

    const response = await this.props.getUserConfig({ providerId });

    this.setState({ hasUserConfig: true });

    if (
      !(response instanceof Error) &&
      !response.providerId &&
      Router.pathname !== '/po'
    ) {
      Router.replace('/po');
      return;
    }

    if (
      this.props.permissions.includes(AUTH_READ_PROVIDER) &&
      response.providerId
    ) {
      this.props.getProviderOperationalData(response.providerId);
    }
  }

  render() {
    const { providerId, children, ...restProps } = this.props;
    const { hasUserConfig } = this.state;

    return (
      <PageContextConsumer>
        {(ctx) => (
          <Layout key={providerId} hideMenu={!ctx.useSidenav} {...restProps}>
            {hasUserConfig ? children : <Loader color={'green'} />}
          </Layout>
        )}
      </PageContextConsumer>
    );
  }
}

const withReducer = applyReducers({
  ...UserConfigReducer,
  ...pageHistoryUrlReducer,
  ...providerOperationalDataReducer,
});
const withRedux = connect(mapStateToProps, mapDispatchToProps);

export default compose(withReducer, withRedux)(LayoutContainer);
