import React, { PureComponent, ReactNode } from 'react';
import styled, { css, cx } from 'react-emotion';
import { FlexBox } from '@traveloka/soya-components';

import TripSideNav, { SideNavMenu } from './TripSideNav';
import TripHeader, { TopBarMenuData } from './TripHeader';

import { tripBodyColor } from '@shared/styles/color';

const LayoutClass = css`
  width: 100%;
  min-height: 100vh;
  padding-top: 64px;
  background-color: ${tripBodyColor};

  &.layout--detail {
    padding-left: 0px;
  }
`;

const WithStickyAction = css`
  padding-bottom: 61px;
`;

const LayoutBody = styled(FlexBox)`
  margin-left: 56px;
  padding: 16px;
  padding-bottom: 96px;
  transition: all 0.5s ease 0s;
  width: calc(100% - 56px);
`;

export interface TripLayoutProps {
  className?: string;
  poName: string;
  stickyAction?: boolean;
  sideNavMenu: SideNavMenu[];
  topBarMenu: TopBarMenuData[];
  centered?: boolean;
  children: ReactNode;
}

export interface TripLayoutState {
  hideMenu: boolean;
}

interface LayoutContentProps {
  hideMenu?: boolean;
}

const LayoutContent = styled('div')`
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  align-items: center;
  justify-content: flex-start;

  ${(props: LayoutContentProps) => {
    const { hideMenu } = props;

    if (hideMenu) {
      return '';
    }

    return `
      &::after {
        background-color: rgba(0, 0, 0, 0.5);
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: fixed;
        z-index: 2;
        content: '';
        top: 0;
        z-index: 3;
      }
    `;
  }}
`;

class TripLayout extends PureComponent<TripLayoutProps, TripLayoutState> {
  constructor(props: TripLayoutProps) {
    super(props);

    this.state = {
      hideMenu: true,
    };

    this.handleSideNavToggle = this.handleSideNavToggle.bind(this);
  }

  handleSideNavToggle() {
    this.setState((prevState) => ({
      hideMenu: !prevState.hideMenu,
    }));
  }

  render() {
    const {
      children,
      className,
      poName,
      sideNavMenu,
      topBarMenu,
      stickyAction = false,
      centered = false,
    } = this.props;
    const { hideMenu } = this.state;

    const classes = cx(
      className,
      LayoutClass,
      // { [WithMenuClass]: !hideMenu },
      { [WithStickyAction]: stickyAction }
    );
    return (
      <div className={classes}>
        <TripHeader menuData={topBarMenu} poName={poName} />
        <LayoutContent hideMenu={hideMenu}>
          <TripSideNav
            hideMenu={hideMenu}
            showWidth={220}
            hideWidth={56}
            menuData={sideNavMenu}
            onToggle={this.handleSideNavToggle}
          />
          <LayoutBody
            direction="column"
            valign={centered ? 'middle' : undefined}
          >
            {children}
          </LayoutBody>
        </LayoutContent>
      </div>
    );
  }
}

export default TripLayout;
