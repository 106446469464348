import { getLocalStorageValue } from '../storage/LocalStorage';

function wrapPayload(
  payloadData?: Object,
  filePayload?: { [key: string]: File },
  tvlkData?: { [key: string]: any }
) {
  const mainRequestPayload = {
    data: payloadData || {},
    context: {
      authServiceToken: getLocalStorageValue('access_token'),
    },
    fields: [],
    clientInterface: (tvlkData && tvlkData.clientInterface) || 'web',
  };

  if (!filePayload) {
    return mainRequestPayload;
  }

  const formData = new FormData();
  formData.append('main-request', JSON.stringify(mainRequestPayload));

  for (const key in filePayload) {
    if (filePayload.hasOwnProperty(key)) {
      const file = filePayload[key];
      formData.append(`data.${key}`, file, file.name);
    }
  }

  return formData;
}

export default wrapPayload;
