import createFetchReducer, {
  FetchState,
} from '@shared/utils/states/createThunkFetchReducer';
import { ProviderOperationalData } from 'trip-extranet/provider';
import {
  startLoadingProviderOperationalData,
  finishLoadingProviderOperationalData,
} from './getProviderOperationalDataActions';

type ProviderOperationalDataState = FetchState<ProviderOperationalData>;

const initialState: ProviderOperationalDataState = {
  initiated: false,
  loading: false,
  successFetch: false,
};

const providerOperationalDataReducer = createFetchReducer({
  startAction: startLoadingProviderOperationalData,
  finishedAction: finishLoadingProviderOperationalData,
  initialState,
});

export interface ProviderOperationalDataReduxState {
  providerOperationalData: ProviderOperationalDataState;
}

export default {
  providerOperationalData: providerOperationalDataReducer,
};
