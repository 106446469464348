// color List
export const tripOxfordBlue = '#354052'; //text color

export const tripAquaHaze = '#EFF3F6';

export const tripRegentGray = '#7F8FA4';

export const tripPaduaGreen = '#ADE7C0';

export const tripHintOfGreen = '#F1FFF5';

export const tripEmerald = '#63C999';
export const tripEmeraldHover = '#8ad6b2';
export const tripEmeraldActive = '#39a773';

export const tripSungloRed = '#E86975';
export const tripAzaleaRed = '#F7D1D5';
export const tripRedLight = '#FCE3E4';
export const tripRed = '#F4555A';

export const tripOrange = '#FF5E1F';
export const tripBlazeOrange = '#f96d01';
export const tripOrangeLight = '#FFF4EF';

export const tripBlack = '#434343';

export const tripWhite = '#ffffff';
export const tripLightStain = '#F7F9FA';
export const tripLightNeutral = '#f2f3f3';

export const tripPictonBlue = '#5BC0EB';
export const tripCalendarBlue = '#1BA0E2';

export const tripLightGrey = '#f5f6f8';

export const tripGrey = '#ccd1d9';
export const tripGrey4 = '#bdbdbd';

export const tripMedGrey = '#FAFAFA';

export const tripDarkNeutral = '#354148';
export const tripDarkSecondary = '#687176';

export const tripInkAlert = '#E7090E';

export const tripGreenPrimary = '#00A651';

export const tripYellowLight = '#fffdf8';

export const tripLigthBlue = '#beffde';

// Base Color
export const tripPrimary = tripEmerald;
export const tripSecondary = tripPictonBlue;
export const tripBodyColor = tripLightStain;
export const tripTextColor = tripOxfordBlue;

export const tripUiPrimary = tripGreenPrimary;
export const tripBlueButton = '#4A9FDC';

// Button Color

export const primaryButtonColor = tripPrimary;
export const primaryButtonColorHover = tripEmeraldHover;
export const primaryButtonColorActive = tripEmeraldActive;
export const secondaryButtonColor = tripSecondary;

// status color
export const tripSuccess = tripEmerald;
export const tripWarning = tripBlazeOrange;
export const tripError = tripAzaleaRed;
export const tripInfo = tripSecondary;

// Link
export const tripLink = tripPictonBlue;
export const tripLinkHover = tripEmeraldHover;

// Border
export const borderColor = '#e4e4e4';
