import React, { ReactNode } from 'react';
import Router from 'next/router';
import Head from 'next/head';
import Link from 'next/link';

import { OIDCAuthorize } from '@shared/utils/soya-auth';
import {
  Icon,
  ModalContainer,
  NotificationContainer,
} from '@traveloka/soya-components';

import getSideNavMenuData from './getSideNavMenuData';

// import PushNotification from '../common/PushNotification/PushNotification';

import TripLayout from '@ui-components/shared/TripLayout/TripLayout';
import { PageContextProvider } from '@shared/contexts/PageContext';

import { IntegrationType } from 'trip-extranet/provider';
import { AUTH_GET_USER_PROFILE } from '@shared/constants/authPermission';

export interface LayoutProps {
  requiredPermissions?: Array<string | string[]>;
  permissions: string[];
  permissionsLoaded?: boolean;
  children?: ReactNode;
  centered?: boolean;
  hideMenu?: boolean;
  marketManagerName: string;
  providerCommercialName: string;
  userEmail: string;
  integrationType?: IntegrationType;
  isInternal?: boolean;
}

export interface State {
  pathName: string;
}

const topBarMenuData = (
  permissions: string[],
  userEmail: string | undefined,
  marketManagerName: string | undefined
) => {
  const accountLinks = [
    <a id="topbar_logout" key={0} href="/logout">
      Logout
    </a>,
  ];

  if (
    userEmail &&
    !(userEmail.indexOf('@traveloka.com') > 0) &&
    permissions.includes(AUTH_GET_USER_PROFILE)
  )
    accountLinks.unshift(
      <Link prefetch href="/user">
        <a id="topbar_userprofile" key={0}>
          Pengaturan Akun
        </a>
      </Link>
    );

  return [
    {
      title: 'Market Manager',
      subtitle: marketManagerName || '',
    },
    {
      title: 'Account',
      subtitle: userEmail || 'Email',
      iconText: <Icon icon="settings" />,
      links: accountLinks,
    },
  ];
};

class Layout extends React.Component<LayoutProps, State> {
  constructor(props: LayoutProps) {
    super(props);

    this.state = {
      pathName: '',
    };
  }
  componentDidMount() {
    this.setState({
      pathName: Router.pathname,
    });
  }

  render() {
    const {
      children,
      centered = false,
      hideMenu = false,
      permissions,
      requiredPermissions = [],
      userEmail,
      providerCommercialName,
      marketManagerName,
      isInternal,
      integrationType,
    } = this.props;

    const { pathName } = this.state;

    const requiredOIDCPermissions: string[] = [];
    for (let permission of requiredPermissions) {
      if (typeof permission === 'string') {
        requiredOIDCPermissions.push(permission);
      }

      if (Array.isArray(permission)) {
        requiredOIDCPermissions.push(...permission);
      }
    }

    return (
      <OIDCAuthorize requiredPermissions={requiredOIDCPermissions}>
        <Head>
          <link rel="shortcut icon" href="/static/favicon.ico" />
        </Head>

        <NotificationContainer />

        <TripLayout
          sideNavMenu={getSideNavMenuData(
            permissions,
            isInternal,
            integrationType,
            pathName
          )}
          topBarMenu={topBarMenuData(permissions, userEmail, marketManagerName)}
          centered={centered}
          poName={providerCommercialName}
        >
          <PageContextProvider value={{ useSidenav: !hideMenu }}>
            {children}
          </PageContextProvider>
        </TripLayout>

        <ModalContainer />
        {/* <PushNotification /> */}
      </OIDCAuthorize>
    );
  }
}

export default Layout;
