import axios from 'axios';
import config from 'config';
import { TvlkResponse } from '@traveloka/commons';
import wrapPayload from './wrapPayload';

const fileUploadConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
    Accept: 'text/csv',
  },
};

export default async function post<T>(
  apiUrl: string,
  payloadData?: Object,
  filePayload?: { [key: string]: File }
): Promise<T> {
  const url = config.apiHost + apiUrl;

  const payload = wrapPayload(payloadData, filePayload);
  const axiosConfig = filePayload ? fileUploadConfig : {};

  try {
    const { data: response } = await axios.post<TvlkResponse<T>>(
      url,
      payload,
      axiosConfig
    );

    const data = response.data;

    // if (data.status === 'FAILED') {
    //   throw new Error(
    //     createErrorMessage('', data.message || 'Error')
    //   );
    // }

    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(`
      Error while trying to connect to ${apiUrl}
      ${error}
    `);

    throw error;
  }
}
