import {
  ActionFunctions,
  CombinedActionType,
  handleAction,
} from 'redux-actions';
import reduceReducer from 'reduce-reducers';

export interface FetchState<Payload> {
  initiated: boolean;
  loading: boolean;
  successFetch: boolean;
  errorMessage?: string;
  data?: Payload;
}

interface Params<State extends FetchState<Payload>, Payload> {
  startAction: string | ActionFunctions<void> | CombinedActionType;
  finishedAction: string | ActionFunctions<Payload> | CombinedActionType;
  initialState: State;
}

function createFetchReducer<State extends FetchState<Payload>, Payload>({
  startAction,
  finishedAction,
  initialState,
}: Params<State, Payload>) {
  const handleStartLoading = handleAction(
    startAction,
    (state, _action) => ({ ...state, loading: true, initiated: true }),
    initialState
  );

  const handleFinishedLoading = handleAction(
    finishedAction,
    (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          loading: false,
          successFetch: false,
        };
      }

      if (action.payload instanceof Error) {
        return {
          ...state,
          loading: false,
          successFetch: false,
          errorMessage: action.payload.message,
        };
      }

      return {
        ...state,
        loading: false,
        successFetch: true,
        data: action.payload,
      };
    },
    initialState
  );

  return reduceReducer(handleStartLoading, handleFinishedLoading);
}

export default createFetchReducer;
