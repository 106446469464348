// const TripLogoIcon = () => {
//   return (
//     <svg id="Layer_1" data-name="Layer 1" viewBox="0 0 1783.09 396.86">
//       <defs>
//         <linearGradient
//           id="linear-gradient"
//           x1="656.46"
//           y1="196.85"
//           x2="720.32"
//           y2="11.39"
//           gradientUnits="userSpaceOnUse"
//         >
//           <stop offset={0} stopColor="#209155" />
//           <stop offset=".05" stopColor="#20a555" />
//           <stop offset=".12" stopColor="#20b455" />
//           <stop offset=".19" stopColor="#20bc55" />
//           <stop offset=".31" stopColor="#20bf55" />
//         </linearGradient>
//         <linearGradient
//           id="linear-gradient-2"
//           x1="-9837.24"
//           y1="17218.36"
//           x2="-9785.81"
//           y2="17084.39"
//           gradientTransform="rotate(180 -4581.17 8728.845)"
//           gradientUnits="userSpaceOnUse"
//         >
//           <stop offset={0} stopColor="#209155" />
//           <stop offset=".11" stopColor="#20a455" />
//           <stop offset=".25" stopColor="#20b855" />
//           <stop offset=".34" stopColor="#20bf55" />
//         </linearGradient>
//         <linearGradient
//           id="linear-gradient-3"
//           x1="254.36"
//           y1="186.73"
//           x2="318.14"
//           y2="11.49"
//           gradientUnits="userSpaceOnUse"
//         >
//           <stop offset={0} stopColor="#209155" />
//           <stop offset=".01" stopColor="#209455" />
//           <stop offset=".08" stopColor="#20a755" />
//           <stop offset=".17" stopColor="#20b555" />
//           <stop offset=".27" stopColor="#20bd55" />
//           <stop offset=".47" stopColor="#20bf55" />
//         </linearGradient>
//         <linearGradient
//           id="linear-gradient-4"
//           x1="-9408.04"
//           y1="17221.92"
//           x2="-9410.33"
//           y2="17090.97"
//           gradientTransform="rotate(180 -4581.17 8728.845)"
//           gradientUnits="userSpaceOnUse"
//         >
//           <stop offset={0} stopColor="#209155" />
//           <stop offset=".01" stopColor="#209755" />
//           <stop offset=".06" stopColor="#20a955" />
//           <stop offset=".12" stopColor="#20b655" />
//           <stop offset=".19" stopColor="#20bd55" />
//           <stop offset=".37" stopColor="#20bf55" />
//         </linearGradient>
//         <linearGradient
//           id="linear-gradient-5"
//           x1="585.87"
//           y1="206.48"
//           x2="636.5"
//           y2="230.09"
//           gradientUnits="userSpaceOnUse"
//         >
//           <stop offset={0} stopColor="#209155" />
//           <stop offset=".45" stopColor="#20bf55" />
//         </linearGradient>
//         <linearGradient
//           id="linear-gradient-6"
//           x1="526.16"
//           y1="223.48"
//           x2="492.29"
//           y2="213.12"
//           gradientUnits="userSpaceOnUse"
//         >
//           <stop offset={0} stopColor="#209155" />
//           <stop offset=".05" stopColor="#209955" />
//           <stop offset=".22" stopColor="#20ae55" />
//           <stop offset=".39" stopColor="#20bb55" />
//           <stop offset=".55" stopColor="#20bf55" />
//         </linearGradient>
//         <linearGradient
//           id="linear-gradient-7"
//           x1="146.9"
//           y1="42.37"
//           x2="21.23"
//           y2="46.76"
//           gradientUnits="userSpaceOnUse"
//         >
//           <stop offset={0} stopColor="#209155" />
//           <stop offset=".14" stopColor="#20b155" />
//           <stop offset=".23" stopColor="#20bf55" />
//         </linearGradient>
//         <linearGradient
//           id="linear-gradient-8"
//           x1="196.03"
//           y1="48.08"
//           x2="251.5"
//           y2="41.27"
//           gradientUnits="userSpaceOnUse"
//         >
//           <stop offset={0} stopColor="#209155" />
//           <stop offset=".23" stopColor="#20b055" />
//           <stop offset=".38" stopColor="#20bf55" />
//         </linearGradient>
//       </defs>
//       <path
//         fill="#20bf55"
//         d="M411.74 219.53v-1c52.62-17.21 79.67-68.85 79.67-116.55 0-35.91-17.7-63-47.21-75.74-14.26-6.4-31.48-8.85-50.17-8.85H270.59l-31.84 163.83H206l-10.33 54.59h32.47l-25.42 130.77h63.45l25.08-130.33h62.95l44.26 130.33h67.87l-46.72-129.35c-4.43-12.79-7.87-17.7-7.87-17.7zm-53.61-38.36h-56.06L323.21 72h57.54c28.53 0 45.74 14.76 45.74 40.82 0 42.77-30.98 68.35-68.36 68.35zM852.9 28.71c-15.25-7.87-33.44-11.31-54.1-11.31H673.88L639.89 191H499.13l-10.33 54.6h140.41l-23.69 121H669l23.6-120.5h76.2a128 128 0 0 0 52.63-11.31c45.24-20.16 77.7-67.37 77.7-123.93 0-37.4-16.72-67.4-46.23-82.15zm-56.56 154.91C786.51 189 774.7 191 762.41 191H702.9l23.61-119H786c31 0 48.19 16.73 48.19 43.78.02 30.96-15.19 56.04-37.85 67.84z"
//       />
//       <path
//         fill="url(#linear-gradient)"
//         d="M673.83 17.3h63.65l-34.5 173.54-62.99.34L673.83 17.3z"
//       />
//       <path
//         fill="url(#linear-gradient-2)"
//         d="M669.14 366.73l-63.65-.34 23.78-120.6 63.32.33-23.45 120.61z"
//       />
//       <path
//         fill="url(#linear-gradient-3)"
//         d="M270.46 17.3h63.66l-32.16 163.83h-62.99L270.46 17.3z"
//       />
//       <path
//         fill="url(#linear-gradient-4)"
//         d="M266.44 366.39l-63.98.34 25.79-130.66h62.99l-24.8 130.32z"
//       />
//       <path
//         fill="url(#linear-gradient-5)"
//         d="M639.99 191l-10.72 54.6h-46.82l10.64-54.6h46.9z"
//       />
//       <path
//         fill="url(#linear-gradient-6)"
//         d="M529.77 191l-10.37 54.6h-30.7l10.36-54.6h30.71z"
//       />
//       <path fill="#20bf55" d="M563.94 17.4h62.95L559 366.58h-63.42z" />
//       <path
//         fill="url(#linear-gradient-7)"
//         d="M32.43 17.3h113.59l-9.64 54.51-114.27.08L32.43 17.3z"
//       />
//       <path
//         fill="url(#linear-gradient-8)"
//         d="M210.01 17.3h38.55l-9.92 54.61-39.7-.1 11.07-54.51z"
//       />
//       <path
//         fill="#20bf55"
//         d="M146.31 17.3h63.74L141.6 366.73H78.25L146.31 17.3z"
//       />
//       <text
//         transform="translate(960.52 114.66)"
//         fontSize="134.89"
//         fill="#444"
//         fontFamily="MuseoSans-300Italic,Museo Sans"
//         fontStyle="italic"
//         letterSpacing=".01em"
//       >
//         TRANSPORT
//         <tspan x={0} y="126.94">
//           INT
//         </tspan>
//         <tspan x="220.14" y="126.94" letterSpacing={0}>
//           E
//         </tspan>
//         <tspan x="297.3" y="126.94">
//           GR
//         </tspan>
//         <tspan className="cls-13" x="486.55" y="126.94">
//           A
//         </tspan>
//         <tspan x="563.85" y="126.94">
//           TED
//         </tspan>
//         <tspan x={0} y="253.87">
//           P
//         </tspan>
//         <tspan x="82.28" y="253.87" letterSpacing=".02em">
//           L
//         </tspan>
//         <tspan className="cls-13" x="153.91" y="253.87">
//           A
//         </tspan>
//         <tspan x="231.2" y="253.87">
//           TFORM
//         </tspan>
//       </text>
//     </svg>
//   );
// };

const TripLogoIcon = () => {
  return (
    <svg width="73" height="27" viewBox="0 0 73 27" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.5515 15.7326V15.8018C32.5515 15.8018 32.8342 16.1888 33.2211 17.1566L37.0454 26.9996H31.4801L27.8492 17.0736H22.6856L20.6321 26.9996H15.4387L17.522 17.046H14.8584L15.7066 12.8848H18.3851L20.9892 0.415039H31.108C32.6408 0.415039 34.0395 0.608583 35.2151 1.09244C37.6406 2.06016 39.0841 4.12002 39.0841 6.85728C39.0841 10.4931 36.8668 14.4193 32.5515 15.7326ZM23.5104 12.4989H28.1938C31.3161 12.4989 33.8928 10.6089 33.8928 7.45891C33.8928 5.5287 32.4529 4.44296 30.0733 4.44296H25.2686L23.5104 12.4989Z"
        fill="#00A651"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.686 0C66.3968 0 67.8975 0.262666 69.1581 0.857122C71.6043 1.97691 72.9849 4.25796 72.9999 7.13346C72.9999 11.4329 70.3137 15.0273 66.5769 16.5618C65.2263 17.1148 63.7256 17.4189 62.2249 17.4189H55.9219L53.971 27H48.6476L50.6844 17.3774H39.084L39.9394 13.2163H51.5698L54.3762 0H64.686ZM61.2111 12.8895C62.1926 12.8895 63.1308 12.7407 63.9102 12.3348C65.7144 11.4554 66.9268 9.5884 66.9124 7.28846C66.9124 5.27263 65.5556 4.02795 63.0875 4.02795H58.3532L56.4768 12.8895H61.2111Z"
        fill="#00A651"
      />
      <path
        d="M52.3273 13.2031L51.3654 17.2413H47.1592L48.1211 13.2031H52.3273Z"
        fill="#00A651"
      />
      <path
        d="M42.6371 13.2031L41.7409 17.2413H39.084L39.9801 13.2031H42.6371Z"
        fill="#00A651"
      />
      <path
        d="M44.9634 0.415039H50.3893L44.5412 26.9996H39.084L44.9634 0.415039Z"
        fill="#00A651"
      />
      <path
        d="M0.848727 0.415039H10.3363L9.53302 4.45321H0L0.848727 0.415039Z"
        fill="#00A651"
      />
      <path
        d="M15.3784 0.415039H18.6427L17.7998 4.45321L14.4436 4.43979L15.3784 0.415039Z"
        fill="#00A651"
      />
      <path
        d="M10.0387 0.415039H15.5045L9.63386 26.9996H4.19922L10.0387 0.415039Z"
        fill="#00A651"
      />
    </svg>
  );
};

export default TripLogoIcon;
