import Router from 'next/router';
import qs from 'querystring';

const getRouterQuerystring = () => {
  const asPath = Router && Router.asPath;

  return asPath ? qs.parse(asPath.split(/\?/)[1]) : undefined;
};

export default getRouterQuerystring;
