function isLocalStorageSupported() {
  return window.localStorage && typeof Storage !== 'undefined';
}

export function getLocalStorageValue(key: string) {
  try {
    if (isLocalStorageSupported()) {
      return window.localStorage.getItem(key);
    }

    return null;
  } catch (error) {
    return null;
  }
}

export function removeLocalStorageValue(key: string) {
  try {
    if (isLocalStorageSupported()) {
      return window.localStorage.removeItem(key);
    }

    return false;
  } catch (error) {
    return false;
  }
}

export function setLocalStorageValue(key: string, value: string) {
  try {
    if (isLocalStorageSupported()) {
      return window.localStorage.setItem(key, value);
    }

    return false;
  } catch (error) {
    return false;
  }
}
