import { DefaultResponse } from 'trip-extranet/services';
import { Dispatch } from 'redux';

interface Params<Payload, Response> {
  startAction: () => any;
  finishedAction: (data: Payload) => any;
  method: () => Promise<Response>;
  extractData: (response: Response) => Payload;
  checkStatus?: boolean;
}

function thunkFetch<Payload, Response extends DefaultResponse>({
  startAction,
  finishedAction,
  method,
  extractData,
  checkStatus = true,
}: Params<Payload, Response>) {
  return async (dispatch: Dispatch) => {
    dispatch(startAction());

    try {
      const response = await method();

      if (checkStatus && response.status !== 'OK') {
        throw new Error(response.message || 'unhandled exception');
      }

      dispatch(finishedAction(extractData(response)));
      return response;
    } catch (error) {
      dispatch(finishedAction(error));
      return error;
    }
  };
}

export default thunkFetch;
