import { handleAction } from 'redux-actions';
import { setPrevUrlAction } from './pageHistoryUrlActions';
import reduceReducer from 'reduce-reducers';
import { UrlLike } from 'trip-extranet/commons';

export interface PageHistoryUrlState {
  prevUrl?: UrlLike;
}

const initialState: PageHistoryUrlState = {
  prevUrl: undefined,
};

const handleChangePrevUrl = handleAction(
  setPrevUrlAction,
  (state, action) => ({ ...state, prevUrl: action.payload }),
  initialState
);

const pageHistoryUrlReducer = reduceReducer(handleChangePrevUrl);

export interface PageHistoryUrlReduxState {
  pageHistory: PageHistoryUrlState;
}

export default {
  pageHistory: pageHistoryUrlReducer,
};
