import React, { ReactNode } from 'react';
import Link from 'next/link';
import { css, cx } from 'react-emotion';
import { Icon, FlexBox } from '@traveloka/soya-components';
import { tripPrimary, tripDarkNeutral } from '@shared/styles/color';

const tripSideNavClass = css`
  background-color: white;
  /* 100vh - TripHeader height */
  height: calc(100vh - 64px);
  transition: all 0.5s ease 0s;
  position: fixed;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-color: light;
  z-index: 5;
  left: 0;
  top: 64px;
  bottom: 0;
`;

const sideNavToggleClass = css`
  border: none;
`;

const menuClass = css`
  background-color: white;
  width: 100%;
  min-height: 40px;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  overflow: hidden;
  white-space: nowrap;

  & a {
    color: ${tripDarkNeutral};
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
`;

const menuTitleClass = css`
  white-space: nowrap;
  margin-left: 10px;
`;

const childMenuTitleClass = css`
  margin-left: 0px;
`;

const menuGroupTitleClass = css`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  white-space: nowrap;
`;

const menuShowClass = css`
  justify-content: flex-start;
  padding: 0px 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
`;

const menuDividerClass = css`
  width: 40%;
  height: 1px;
  border: 1px solid #dce1e8;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const childMenuClass = css`
  left: 55px;
  padding: 20px 10px;
  border: 1px solid #e4e4e4;
  border-left: 0;
  position: fixed;
  background: white;
  max-height: 300px;
  overflow: scroll;
`;

const childTitleClass = css`
  font-size: 12px;
  margin-bottom: 15px;
  letter-spacing: 1px;
  white-space: nowrap;
  opacity: 0.5;
`;

const childContentClass = css`
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 1px;
  opacity: 0.8;
  white-space: nowrap;
  color: #354148;
  margin-left: 10px;
`;

const activeMenu = css`
  background: #f4fcf7;
  border-left: 4px solid #00a651 !important;
`;

export interface SideNavMenu {
  title: string;
  icon?: ReactNode;
  href?: string;
  isOld?: boolean;
  children?: SideNavMenu[];
  isActive?: boolean;
}

interface State {
  isParentClicked: boolean;
  parentIndex: number;
}

interface State {
  isParentClicked: boolean;
  parentIndex: number;
}

interface Props {
  menuData: SideNavMenu[];
  className?: string;
  hideMenu?: boolean;
  hideWidth?: string | number;
  showWidth?: string | number;
  onToggle: () => void;
}

class TripSideNav extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isParentClicked: false,
      parentIndex: 0,
    };

    this.handleParentMenuClicked = this.handleParentMenuClicked.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
  }

  handleToggle() {
    const { onToggle } = this.props;

    this.setState(
      {
        isParentClicked: false,
      },
      onToggle
    );
  }

  handleParentMenuClicked(index: number) {
    const { hideMenu } = this.props;
    const { isParentClicked } = this.state;

    const isClicked = hideMenu ? !isParentClicked : false;

    this.setState((prevState) => ({
      isParentClicked: index === prevState.parentIndex ? isClicked : true,
      parentIndex: index,
    }));
  }

  render() {
    const {
      className,
      menuData,
      hideMenu,
      hideWidth = 56,
      showWidth = 220,
    } = this.props;

    const { isParentClicked, parentIndex } = this.state;

    const classNames = cx(className, tripSideNavClass);

    const oldMenu = menuData.filter((_menu) => _menu.isOld);
    const newMenu = menuData.filter((_menu) => !_menu.isOld);

    return (
      <div
        className={classNames}
        style={{ width: hideMenu ? hideWidth : showWidth }}
      >
        <button
          className={cx(sideNavToggleClass, menuClass)}
          onClick={this.handleToggle}
        >
          <Icon icon={'view-list'} color={tripPrimary} />
        </button>
        {hideMenu &&
          menuData.map((_menu, index) => (
            <FlexBox key={`${_menu.title}_${index}`}>
              <FlexBox
                onClick={() => this.handleParentMenuClicked(index)}
                className={cx(menuClass, {
                  [menuShowClass]: !hideMenu,
                  [activeMenu]: _menu.isActive,
                })}
              >
                <div>
                  {_menu.href ? (
                    <Link prefetch href={_menu.href}>
                      <a>{_menu.icon}</a>
                    </Link>
                  ) : (
                    _menu.icon
                  )}
                </div>
              </FlexBox>
              {index === parentIndex &&
                _menu.children &&
                isParentClicked &&
                hideMenu && (
                  <div className={childMenuClass}>
                    <div className={childTitleClass}>
                      {_menu.title.toUpperCase()}
                    </div>
                    {_menu.children.map((child, childIndex) => (
                      <FlexBox
                        key={childIndex}
                        paddingBottom={10}
                        valign={'middle'}
                      >
                        <Link prefetch href={child.href || ''}>
                          <a>
                            <FlexBox valign={'middle'}>
                              {child.icon}
                              <span className={childContentClass}>
                                {child.title}
                              </span>
                            </FlexBox>
                          </a>
                        </Link>
                      </FlexBox>
                    ))}
                  </div>
                )}
            </FlexBox>
          ))}
        {!hideMenu &&
          newMenu.map((_menu, index) =>
            _menu.children ? (
              _menu.children.map((child) => (
                <div
                  className={cx(menuClass, menuShowClass, {
                    [activeMenu]: child.isActive,
                  })}
                >
                  <Link prefetch href={child.href || ''}>
                    <a key={index}>
                      {child.icon}
                      <span className={menuTitleClass}>{child.title}</span>
                    </a>
                  </Link>
                </div>
              ))
            ) : (
              <div
                className={cx(menuClass, menuShowClass, {
                  [activeMenu]: _menu.isActive,
                })}
              >
                <Link prefetch href={_menu.href || ''}>
                  <a key={index}>
                    {_menu.icon}
                    <span className={menuTitleClass}>{_menu.title}</span>
                  </a>
                </Link>
              </div>
            )
          )}

        {!hideMenu && oldMenu.length > 0 && (
          <div className={menuDividerClass} />
        )}
        {!hideMenu &&
          oldMenu.map((_menu, index) => (
            <React.Fragment>
              <div className={cx(menuClass, menuShowClass)}>
                <div key={index}>
                  <span className={menuGroupTitleClass}>
                    {_menu.title.toUpperCase()}
                  </span>
                </div>
              </div>
              {(_menu.children || []).map((childMenu: SideNavMenu) => (
                <div
                  className={cx(menuClass, menuShowClass, {
                    [activeMenu]: childMenu.isActive,
                  })}
                >
                  <Link prefetch href={childMenu.href || ''}>
                    <a key={index}>
                      <span className={cx(menuTitleClass, childMenuTitleClass)}>
                        {childMenu.title}
                      </span>
                    </a>
                  </Link>
                </div>
              ))}
            </React.Fragment>
          ))}
      </div>
    );
  }
}

export default TripSideNav;
