import post from '@shared/utils/services/post';
import { DefaultResponse } from 'trip-extranet/services';
import { ProviderProfile } from 'trip-extranet/provider';

export interface FetchProviderProfileResponse extends DefaultResponse {
  profile: ProviderProfile;
}

export default function fetchProviderProfile(spec: { providerId: string }) {
  return post<FetchProviderProfileResponse>(
    '/v2/busextranetprovider/provider/profile',
    spec
  );
}
