// -- PERMISSION
export const AUTH_ACCESS_TRIP = 'Bxt:trip';

export const AUTH_SELECT_AND_SWITCH_PO = 'BXT:U:POSel';

export const AUTH_CREATE_PROVIDER = 'BXT:C:PO';
export const AUTH_READ_PROVIDER = 'BXT:R:PO';
export const AUTH_UPDATE_PROVIDER = 'BXT:U:PO';
export const AUTH_DELETE_PROVIDER = 'BXT:D:PO';

export const AUTH_CREATE_SKU = 'BXT:C:SKU';
export const AUTH_READ_SKU = 'BXT:R:SKU';
export const AUTH_UPDATE_SKU = 'BXT:U:SKU';
export const AUTH_DELETE_SKU = 'BXT:D:SKU';

export const AUTH_ARCHIVE_SKU = 'BXT:U:SKUArchv';
export const AUTH_DUPLICATE_SKU = 'BXT:U:SKUDup';
export const AUTH_UPDATE_SKU_LIST = 'BXT:U:SKUList';

export const AUTH_UPDATE_ROUTE = 'BXT:U:Routes';
export const AUTH_UPDATE_COURSE = 'BXT:U:Course';
export const AUTH_UPDATE_ROUTES_FARE = 'BXT:U:RoutesFare';
export const AUTH_UPDATE_ALLOTMENT = 'BXT:U:Allotment';
export const AUTH_UPDATE_BULK_ALLOTMENT = 'BXT:U:BulkAllotment';

export const AUTH_READ_ALLOTMENT_ALL_ROUTES = 'Bxt:R:AltRts';

export const AUTH_CREATE_SKU_NON_MODERATED = 'BXT:C:SKUMod';
export const AUTH_READ_SKU_NON_MODERATED = 'BXT:R:SKUMod';
export const AUTH_UPDATE_SKU_NON_MODERATED = 'BXT:U:SKUMod';
export const AUTH_DELETE_SKU_NON_MODERATED = 'BXT:D:SKUMod';

export const AUTH_UPDATE_SKU_VESSEL_LAYOUT = 'BXT:U:Vessel';

export const AUTH_READ_SKU_REFUND_RESCHEDULE = 'BXT:R:Refsch';
export const AUTH_UPDATE_SKU_REFUND_RESCHEDULE = 'BXT:U:Refsch';

export const AUTH_READ_SKU_TTD_VALUE = 'BXT:R:TTD';
export const AUTH_UPDATE_SKU_TTD_VALUE = 'BXT:U:TTD';

export const AUTH_CREATE_SKU_VIA = 'BXT:C:Via';
export const AUTH_READ_SKU_VIA = 'BXT:R:Via';
export const AUTH_UPDATE_SKU_VIA = 'BXT:U:Via';
export const AUTH_DELETE_SKU_VIA = 'BXT:D:Via';

export const AUTH_READ_PAX_MANIFEST = 'BXT:R:EPxMnf';
export const AUTH_UPDATE_PAX_MANIFEST = 'BXT:U:EPxMnf';

export const AUTH_DOWNLOAD_PAX_MANIFEST = 'BXT:U:EDwlPxMnf';

export const AUTH_READ_BOOKING_MANIFEST_DETAIL = 'BXT:R:BkMnf';
export const AUTH_UPDATE_BOOKING_MANIFEST_DETAIL = 'BXT:U:BkMnf';

export const AUTH_READ_RESERVATION = 'Bxt:R:Res';
export const AUTH_UPDATE_RESERVATION = 'Bxt:U:Res';

export const AUTH_READ_ACCOUNT_MANAGEMENT = 'BXT:R:AccMgmt';
export const AUTH_CREATE_ACCOUNT_MANAGEMENT = 'BXT:C:AccMgmt';
export const AUTH_UPDATE_ACCOUNT_MANAGEMENT = 'BXT:U:AccMgmt';
export const AUTH_DELETE_ACCOUNT_MANAGEMENT = 'BXT:D:AccMgmt';

export const AUTH_READ_VEHICLE_MANAGEMENT = 'BXT:R:VhcMgmt';
export const AUTH_CREATE_VEHICLE_MANAGEMENT = 'BXT:C:VhcMgmt';
export const AUTH_UPDATE_VEHICLE_MANAGEMENT = 'BXT:U:VhcMgmt';
export const AUTH_DELETE_VEHICLE_MANAGEMENT = 'BXT:D:VhcMgmt';

export const AUTH_READ_CONTROL_SETTINGS = 'BXT:R:CtrlStg';
export const AUTH_UPDATE_CONTROL_SETTINGS = 'BXT:U:CtrlStg';

export const AUTH_READ_ENABLEMENT_INVENTORY_API = 'BXT:R:EDInv';
export const AUTH_UPDATE_ENABLEMENT_INVENTORY_API = 'BXT:U:EDInv';

export const AUTH_READ_BANK_LIST = 'BXT:R:Bank';
export const AUTH_BULK_UPLOAD_SKU_PHOTOS = 'BXT:U:BulkPhoto';
export const AUTH_BULK_EDIT_SKU_PROFILE = 'BXT:U:BulkSKU';
export const AUTH_ADD_UPDATE_POINT_CITY = 'BXT:U:CityPoint';
export const AUTH_READ_POINT_CITY = 'BXT:R:CityPoint';
export const AUTH_READ_PROVIDER_POINT_MAPPING = 'BXT:R:PointMap';
export const AUTH_ADD_PROVIDER_POINT_MAPPING = 'BXT:U:PointMap';
export const AUTH_DUPLICATE_MULTI_SKU_FARES = 'BXT:U:DuplMultiFares';

export const AUTH_READ_RUN_API_TEST = 'BXT:R:RunAPITest';
export const AUTH_READ_ENABLE_CONFIG = 'BXT:R:EnblCfg';
export const AUTH_UPDATE_ENABLE_CONFIG = 'BXT:U:EnblCfg';
export const AUTH_READ_PROVIDER_CONFIG_ENABLE = 'BXT:R:OPDATA';
export const AUTH_UPDATE_PROVIDER_CONFIG_ENABLE = 'BXT:U:OPDATA';
export const AUTH_READ_CONFIG_BXT = 'BXT:R:cfgV2';

export const AUTH_READ_ANALYTICS_DASHBOARD = 'BXT:R:TAD';
export const AUTH_UPDATE_ANALYTICS_DASHBOARD = 'BXT:U:TAD';

export const AUTH_MANAGE_ROUTE_ON_AND_OFF = 'BXT:U:RouteOffArea';
export const AUTH_READ_ALLOTMENT_SEGMENT = 'BXT:R:AltSeg';

export const AUTH_CHECK_REMAINING_ALLOTMENT = 'BXT:R:CheckAllotment';

export const AUTH_GET_USER_PROFILE = 'Bxt:R:APUsrPrfl';

export const AUTH_NEED_MODERATION = 'BXT:U:NeedMdrt';
export const AUTH_READ_FARE_MODERATION = 'BXT:U:NeedMdrt';
export const AUTH_READ_USER_HISTORY_ALL_SKU = 'BXT:R:AllHistoryList';
export const AUTH_READ_USER_HISTORY_LOG = 'BXT:R:HTR';

export const AUTH_READ_ALLOTMENT_MONITORING = 'BXT:R:AMR';
export const AUTH_READ_INVENTORY_SEARCH_CITY = 'BXT:R:InvSrchCity';

export const AUTH_READ_PRICING_ENGINE = 'BXT:R:PKG';
export const AUTH_UPDATE_PRICING_ENGINE = 'BXT:U:PKG';

export const AUTH_READ_PRICING_SIMULATOR = 'BXT:R:PRCSIM';

export const AUTH_READ_PREBUY = 'BXT:R:Prby';
export const AUTH_UPDATE_PREBUY = 'BXT:U:Prby';

/* Unused for now 
export const AUTH_READ_BOOKING_DETAIL = 'BXT:R:BkgDtl';
export const AUTH_UPDATE_BOOKING_DETAIL = 'BXT:U:BkgDtl';
*/

// -- ROLE PERMISSION MAPPING
/**
 * If you add new role, please concat it to superuser role
 */

export const ROLE_PO_MANAGER = [
  AUTH_SELECT_AND_SWITCH_PO,
  AUTH_CREATE_PROVIDER,
  AUTH_READ_PROVIDER,
  AUTH_UPDATE_PROVIDER,
  AUTH_DELETE_PROVIDER,
];

export const ROLE_PO_READ_ONLY = [AUTH_READ_PROVIDER];

export const ROLE_PO_SELECTION_MANAGEMENT = [AUTH_SELECT_AND_SWITCH_PO];

export const ROLE_SKU_MANAGEMENT = [
  AUTH_CREATE_SKU,
  AUTH_READ_SKU,
  AUTH_UPDATE_SKU,
  AUTH_DELETE_SKU,
];

export const ROLE_VESSEL_LAYOUT_MANAGEMENT = [AUTH_UPDATE_SKU_VESSEL_LAYOUT];

export const ROLE_SKU_READ_ONLY = [AUTH_READ_SKU];

export const ROLE_PAX_MANIFEST_ACCESS = [
  AUTH_READ_PAX_MANIFEST,
  AUTH_UPDATE_PAX_MANIFEST,
  AUTH_DOWNLOAD_PAX_MANIFEST,
];

export const ROLE_PAX_MANIFEST_READ_ONLY = [
  AUTH_READ_PAX_MANIFEST,
  AUTH_DOWNLOAD_PAX_MANIFEST,
];

export const ROLE_BOOKING_MANIFEST_READ_ONLY = [
  AUTH_READ_BOOKING_MANIFEST_DETAIL,
];

export const ROLE_BOOKING_MANIFEST_MANAGEMENT = [
  AUTH_READ_BOOKING_MANIFEST_DETAIL,
  AUTH_UPDATE_BOOKING_MANIFEST_DETAIL,
];

export const ROLE_SKU_NON_MODERATED_INTERNAL_MANAGEMENT = [
  AUTH_CREATE_SKU_NON_MODERATED,
  AUTH_READ_SKU_NON_MODERATED,
  AUTH_UPDATE_SKU_NON_MODERATED,
  AUTH_DELETE_SKU_NON_MODERATED,

  AUTH_READ_SKU_REFUND_RESCHEDULE,
  AUTH_UPDATE_SKU_REFUND_RESCHEDULE,

  AUTH_READ_SKU_TTD_VALUE,
  AUTH_UPDATE_SKU_TTD_VALUE,
];

export const ROLE_ACCOUNT_MANAGEMENT = [
  AUTH_CREATE_ACCOUNT_MANAGEMENT,
  AUTH_READ_ACCOUNT_MANAGEMENT,
  AUTH_UPDATE_ACCOUNT_MANAGEMENT,
  AUTH_DELETE_ACCOUNT_MANAGEMENT,
];

export const ROLE_ACCOUNT_MANAGEMENT_READ_ONLY = [AUTH_READ_ACCOUNT_MANAGEMENT];

export const ROLE_VEHICLE_MANAGEMENT = [
  AUTH_CREATE_VEHICLE_MANAGEMENT,
  AUTH_READ_VEHICLE_MANAGEMENT,
  AUTH_UPDATE_VEHICLE_MANAGEMENT,
  AUTH_DELETE_VEHICLE_MANAGEMENT,
];

export const ROLE_VEHICLE_MANAGEMENT_READ_ONLY = [AUTH_READ_VEHICLE_MANAGEMENT];

export const ROLE_RESERVATION_READ_ONLY = [AUTH_READ_RESERVATION];

export const ROLE_RESERVATION_MANAGEMENT = [
  AUTH_READ_RESERVATION,
  AUTH_UPDATE_RESERVATION,
];

export const ROLE_CONTROL_SETTINGS = [
  AUTH_READ_CONTROL_SETTINGS,
  AUTH_UPDATE_CONTROL_SETTINGS,
];

export const ROLE_ALL_POSTMAN_UTILS = [
  AUTH_BULK_UPLOAD_SKU_PHOTOS,
  AUTH_BULK_EDIT_SKU_PROFILE,
  AUTH_ADD_UPDATE_POINT_CITY,
  AUTH_ADD_PROVIDER_POINT_MAPPING,
  AUTH_READ_BANK_LIST,
  AUTH_READ_POINT_CITY,
];

/* Unused for now 

export const ROLE_BOOKING_CHECK_ACCESS = [
  AUTH_READ_BOOKING_DETAIL,
  AUTH_UPDATE_BOOKING_DETAIL,
];
*/

export const ROLE_ALLOTMENT_MONITORING_READ_ONLY = [
  AUTH_READ_ALLOTMENT_MONITORING,
];

export const ROLE_PRICING_ENGINE_READ_ONLY = [AUTH_READ_PRICING_ENGINE];

export const ROLE_PRICING_ENGINE = [
  AUTH_READ_PRICING_ENGINE,
  AUTH_UPDATE_PRICING_ENGINE,
];

export const ROLE_PRICING_SIMULATOR_READ_ONLY = [AUTH_READ_PRICING_SIMULATOR];

export const ROLE_SUPERUSER = ROLE_PO_MANAGER.concat(ROLE_SKU_MANAGEMENT)
  .concat(ROLE_PAX_MANIFEST_ACCESS)
  .concat(ROLE_BOOKING_MANIFEST_MANAGEMENT)
  .concat(ROLE_SKU_NON_MODERATED_INTERNAL_MANAGEMENT)
  .concat(ROLE_VESSEL_LAYOUT_MANAGEMENT)
  .concat(ROLE_CONTROL_SETTINGS)
  .concat(ROLE_ALL_POSTMAN_UTILS)
  .concat(ROLE_ALLOTMENT_MONITORING_READ_ONLY)
  .concat(ROLE_PRICING_ENGINE_READ_ONLY);
//  .concat(ROLE_BOOKING_CHECK_ACCESS)
//  .concat(ROLE_ACCOUNT_MANAGEMENT);
