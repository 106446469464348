import { DefaultResponse } from 'trip-extranet/services';
import post from '@shared/utils/services/post';

interface FetchUserConfigResponse extends DefaultResponse {
  userPermission: any;
  providerId?: string;
  providerCommercialName?: string;
  marketManagerName?: string;
  userEmail: string;
  isInternal: boolean;
}

export interface FetchUserConfigSpec {
  providerId?: string;
}

function fetchUserConfig(payload: FetchUserConfigSpec) {
  return post<FetchUserConfigResponse>(
    '/v2/busextranetoperator/provider/getUserConfig',
    payload
  );
}

export default fetchUserConfig;
