import { OIDC_PERMISSION_KEY } from 'shared/constants/oidcToken';

export const getPermissions = (state: any): string[] => {
  const legacyPermissions =
    state?.oidc?.token?.tokenBody?.['permissions'] ?? [];
  let cognitoPermissions =
    state?.oidc?.token?.tokenBody?.[OIDC_PERMISSION_KEY] ?? [];

  if (typeof cognitoPermissions === 'string') {
    cognitoPermissions = JSON.parse(cognitoPermissions);
  }

  return [...legacyPermissions, ...cognitoPermissions];
};
