import { DefaultResponse } from 'trip-extranet/services';
import { ProviderOperationalData } from 'trip-extranet/provider';
import post from '@shared/utils/services/post';

export interface GetProviderOperationalDataSpec {
  providerId: string;
}

export interface GetProviderOperationalDataResponse extends DefaultResponse {
  operationalData: ProviderOperationalData;
}

async function getProviderOperationalData(
  payload: GetProviderOperationalDataSpec
): Promise<GetProviderOperationalDataResponse> {
  return post<GetProviderOperationalDataResponse>(
    '/v2/busextranetprovider/provider/operational',
    payload
  );
}

export default getProviderOperationalData;
