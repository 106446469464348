import { createAction } from 'redux-actions';
import thunkFetch from '@shared/utils/states/thunkFetch';
import fetchProviderProfile from '../services/fetchProviderProfile';
import { ProviderProfile } from 'trip-extranet/provider';

export const startLoadingProviderProfile = createAction(
  'PROVIDER-PROFILE/START_LOADING_PROVIDER_PROFILE'
);
export const finishLoadingProviderProfile = createAction<ProviderProfile>(
  'PROVIDER-PROFILE/FINISH_LOADING_PROVIDER_PROFILE'
);

export function fetchProviderProfileAction(providerId: string) {
  return thunkFetch({
    startAction: startLoadingProviderProfile,
    finishedAction: finishLoadingProviderProfile,
    method: () => fetchProviderProfile({ providerId }),
    extractData: (response) => {
      return response.profile;
    },
  });
}
