import { createAction } from 'redux-actions';
import { ProviderOperationalData } from 'trip-extranet/provider';
import thunkFetch from '@shared/utils/states/thunkFetch';
import getProviderOperationalData from '../services/getProviderOperationalData';

export const startLoadingProviderOperationalData = createAction(
  'PROVIDER-OPERATIONAL/START_LOADING_PROVIDER_OPERATIONAL_DATA'
);

export const finishLoadingProviderOperationalData = createAction<
  ProviderOperationalData
>('PROVIDER-OPERATIONAL/FINISH_LOADING_PROVIDER_OPERATIONAL_DATA');

export function getProviderOperationalDataAction(providerId: string) {
  return thunkFetch({
    startAction: startLoadingProviderOperationalData,
    finishedAction: finishLoadingProviderOperationalData,
    method: () => getProviderOperationalData({ providerId }),
    extractData: (response) => response.operationalData,
  });
}
